import React, { useContext } from 'react';
import { Route, ScrollToTop, Routes } from '@thd-olt-component-react/router';
import { AppContext } from '@thd-nucleus/app-render';

import { Error } from './pages';
import { getRoutes } from './routes';
import { deriveCustomerType, getPageVariant } from './utils';
import { useSlug } from './hooks';
import './styles/app.scss';

export const App = () => {
  const { instance } = useContext(AppContext);
  const { customer, authHeaders, thdSEOBotDetection } = instance;
  const storeFrontType = customer.type !== 'b2b' ? instance.theme?.name : null;
  const customerType = deriveCustomerType({
    customerType: customer.type,
    authHeaders,
    storeFrontType
  });
  /* Below We need to invert the guest customer type for our slug.
  This is because of the change they made in Contentful forcing either b2c or b2b to be at the end of the slug */
  const slugCustomerType = customerType === 'b2c-guest' ? 'guest-b2c' : customerType;
  const isNativeApp = instance.expContext.experienceContext.isConsumerApp;
  const {
    currentRouteName,
    currentRouteComponent
  } = instance.router;

  const pageVariant = getPageVariant(customerType);
  const route = getRoutes(currentRouteName, currentRouteComponent)[pageVariant];
  const PageComponent = route.component;
  const slug = useSlug(slugCustomerType, isNativeApp);

  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route
          exact
          path={route.path}
          element={
            <PageComponent slug={slug} thdSEOBotDetection={thdSEOBotDetection} />
          }
        />
        <Route path="/error" element={<Error />} />
        <Route element={<Error />} />
      </Routes>
    </>
  );
};
