import { useLocation } from '@thd-olt-component-react/router';

export function useSlug(customerType, isNativeApp = false) {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const slugFromQueryParams = searchParams.get('slug');
  const debugTrue = searchParams.get('debug');

  /**
   * A slug in the query parameters means someone is trying to preview
   * the page using the preview button in Contentful.
   */
  if (debugTrue === 'true' && slugFromQueryParams) return slugFromQueryParams;

  if (isNativeApp) return `native-app-homepage-prod-${customerType}`;

  return `homepage-prod-${customerType}`;
}
