import { useBreakpoint } from '@one-thd/sui-atomic-components';
import { getHydrationOptions } from './constants';
import { triggerAnalytics } from './analytics';

const thdCustomer = typeof window !== 'undefined' ? window.THDCustomer?.default : null;
const mcVisFromWindow = typeof window !== 'undefined' ? window.s?.marketingCloudVisitorID : null;

export const MARKETING_CLOUD_VISITOR_ID_DEFAULT = '12345-Homepage';

export const CUSTOMER_TYPES = {
  GUEST: 'b2c-guest',
  B2C: 'b2c',
  B2B: 'b2b',
  EPROCUREMENT: 'eprocurement-b2b',
  EXCHANGE: 'exchange-b2b'
};

export const STOREFRONT_TYPES = {
  EPROCUREMENT: 'EPROCUREMENT',
  EXCHANGE: 'EXCHANGE',
};

export const AUTHORIZATION_STATUS = {
  AUTHENTICATED: 'authenticated',
  PERSISTED: 'persisted',
  GUEST: 'guest',
  EPROCUREMENT: 'eprocurement',
  EXCHANGE: 'exchange'
};

const HOMEPAGE_VARIANTS = {
  [CUSTOMER_TYPES.B2C]: 'consumerHomepage',
  [CUSTOMER_TYPES.B2B]: 'proHomepage',
  [CUSTOMER_TYPES.GUEST]: 'guestHomepage',
  [CUSTOMER_TYPES.EPROCUREMENT]: 'eprocurementHomepage',
  [CUSTOMER_TYPES.EXCHANGE]: 'exchangeHomepage',
};

/**
 * The `x-thd-customer-token` auth header is the JWT. This will only be present for non-guests.
 *
 * So, if we don't see that header, we can assume your customer type is guest. If you have that header,
 * then `customerType` already indicates the type of logged-in customer you are and matches the B2C and B2B
 * constants above.
 */
function deriveCustomerType({ customerType, authHeaders, storeFrontType }) {
  if (storeFrontType) {
    return CUSTOMER_TYPES[storeFrontType.toUpperCase()];
  }
  if (!authHeaders['x-thd-customer-token']) {
    return CUSTOMER_TYPES.GUEST;
  }

  return CUSTOMER_TYPES[customerType.toUpperCase()];
}

function getSvocId() {
  return thdCustomer?.svocID || null;
}

function _convertCrumbsToJson(crumbs) {
  let propName = null;
  // eslint-disable-next-line id-length
  const crumbData = crumbs.reduce(function _reduce(a, b) {
    if (propName) {
      // eslint-disable-next-line no-param-reassign
      a[propName] = b;
      propName = null;
    } else {
      propName = b;
    }
    return a;
  }, {});

  return crumbData;
}

function getMarketingCloudVisitorIDFromCookie() {
  try {
    const theCookie = window?.cookieUtils?.readBrowserCookie(
      'AMCV_F6421253512D2C100A490D45%40AdobeOrg',
    );
    let crumbs = theCookie ? theCookie.split('|') : [];
    if (crumbs.length > 1) {
      crumbs.shift(); // custom for adobe kill first value
    }
    const crumbData = _convertCrumbsToJson(crumbs);

    return (crumbData && crumbData.MCMID) || '';
    // eslint-disable-next-line id-length
  } catch (e) {
    return null;
  }
}

function getMarketingCloudVisitorID() {
  return (
    thdCustomer?.mcvisID
    || getMarketingCloudVisitorIDFromCookie()
    || mcVisFromWindow
    || MARKETING_CLOUD_VISITOR_ID_DEFAULT
  );
}

function getUserId() {
  return thdCustomer?.userID || null;
}

function getGuestUserId() {
  return thdCustomer?.guestUserID || null;
}

function getProCustomerSegment() {
  return thdCustomer?.segment || null;
}

function userAuthorizationStatus() {
  if (thdCustomer?.isLoggedIn) {
    if (!thdCustomer?.isKMSI && thdCustomer?.isPersisted) {
      return AUTHORIZATION_STATUS.PERSISTED;
    }
    return AUTHORIZATION_STATUS.AUTHENTICATED;
  }
  return AUTHORIZATION_STATUS.GUEST;
}

function isGuest() {
  return thdCustomer?.isLoggedIn;
}

function getPageVariant(customerType) {
  return HOMEPAGE_VARIANTS[customerType] || 'guestHomepage';
}

const getPersonalizedDealsUserId = (customerType) => {
  return customerType === CUSTOMER_TYPES.B2B
    ? getSvocId() + '_' + getUserId()
    : getMarketingCloudVisitorID();
};

const useMobileBreakpoint = () => {
  const { lesser: isMobile } = useBreakpoint('lg');
  return isMobile;
};

export {
  deriveCustomerType,
  getSvocId,
  getMarketingCloudVisitorID,
  getUserId,
  getGuestUserId,
  getProCustomerSegment,
  userAuthorizationStatus,
  isGuest,
  getHydrationOptions,
  getPageVariant,
  triggerAnalytics,
  getPersonalizedDealsUserId,
  useMobileBreakpoint
};
